.Background {
  background: url(../public/background-top.jpg), url(../public/background-bot.jpg);
  background-repeat: no-repeat;
  background-position: top right, bottom left;
  min-height: 10vh;
  background-attachment: fixed;
  background-size: 25vw 25vh;
  margin-top: -8px;
  margin-bottom: -8px;
  margin-left: -6px;
  margin-right: -6px;
}

/* if screen size is less than 500px */
@media (max-width: 500px) {
  .Background {
    background: url(../public/background-top.jpg), url(../public/background-bot.jpg);
    background-repeat: no-repeat;
    background-position: top right, bottom left;
    background-size: 70vw 20vh;
    background-attachment: fixed;
  }
}

.LanguageDropdown {
  float: right;
  margin: 20px;
}
.LanguageDropdown select {
  padding: 8px;
  font-size: 20px;
  border: 2px solid #333;
  border-radius: 5px;
  background-color: #f8f8f8;
  width: 85px;
  height: 55px;
  color:#333;
}
.Navbar {
  position: sticky;
  float: left;
  top: 20px;
}

/* Schedule */
.row {
  display: flex;
  justify-content: center;
}
.column-left {
  float: left;
  width: 10%;
  text-align: right;
  padding-right: 10px;
}
.column-right {
  float: right;
  width: 40%;
  text-align: left;
  padding-left: 10px;
}

.RSVP .row {
  display: flex;
  justify-content: center;
  margin-top: -10px;
}
.RSVP .column-left {
  float: left;
  width: 10%;
  text-align: right;
  padding-right: 10px;
}
.RSVP .column-right {
  float: right;
  width: 60%;
  text-align: left;
  padding-left: 10px;
}
.RSVP .view .column-left {
  float: left;
  width: clamp(50px, 80px, 50%);
  text-align: right;
  padding-right: 10px;
}
.RSVP .view .column-right {
  float: right;
  width: clamp(100px, 300px, 400px);
  text-align: left;
  padding-left: 10px;
}
.RSVP .edit .row {
  display: flex;
  justify-content: center;
  margin-top: -10px;
  align-items: center;
}
.RSVP .edit .column-left {
  float: left;
  width: 30%;
  text-align: right;
  padding-right: 10px;
}
.RSVP .edit .column-right {
  float: right;
  width: clamp(200px, 60%, 800px);
  text-align: left;
  padding-left: 10px;
}

/* Q and A */
.qa {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: clamp(300px, 90vw, 800px); /* clamp(min_value, preferred, max) */
  margin: auto;
  text-align: justify;
}

.qa h2, .qa .MuiAccordion-root {
  width: 100%;
  text-align: center;
}

.css-1vuohza-MuiTabs-root .MuiTabs-indicator {
  background-color: #000000;
}

/* B3C8CF BED7DC F1EEDC E5DDC5*/

.breadcrumb {
  display: flex;
  justify-content: center;
}


/* Homepage.css */
.Homepage {
  padding-top: 10vh;
  padding-bottom: 20vh;
  text-align: center;
  font-family: Garamond, serif;
}


.Homepage h1 {
  font-size: calc(40px + 2vmin);
  color: #333;
  
}

.Homepage h2 {
  font-size: calc(30px + 2vmin);
  color: #333;
}

.Homepage h3 {
  font-size: calc(20px + 2vmin);
  color: #333;
}

.Homepage h4 {
  font-size: calc(15px + 2vmin);
  color: #666;
}

.Homepage h5 {
  font-size: calc(4px + 2vmin);
  color: #000000;
  font-weight: lighter;
}

.Homepage p {
  font-size: calc(10px + 2vmin);
  color: #666;
}
.RSVP textarea {
  width: clamp(250px, 70vw, 400px);
  height: 80px;
  padding: 10px;
  margin: 10px;
  font-family: 'Garamond', serif;
  box-sizing: border-box;
  border: 3px solid #ccc;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  font-size: medium;
}
.RSVP textarea:focus {
  border: 3px solid #555;
  
}
.RSVP input[type=text] {
  width: 50%;
  padding: 10px;
  margin: 10px;
  font-family: 'Garamond', serif;
  box-sizing: border-box;
  border: 3px solid #ccc;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  font-size: medium;
}

.RSVP .meal input[type=text] {
  width: clamp(150px, 50%, 400px);
  padding: 10px;
  margin: 10px;
  font-family: 'Garamond', serif;
  box-sizing: border-box;
  border: 3px solid #ccc;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  font-size: medium;
}
.RSVP input[type=text]:focus {
  border: 3px solid #555;
  
}
.RSVP .edit input[type=text] {
  width: clamp(100px, 90%, 500px);
  padding: 10px;
  margin: 10px;
  font-family: 'Garamond', serif;
  box-sizing: border-box;
  border: 3px solid #ccc;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  font-size: medium;
}
.RSVP .edit input[type=text]:focus {
  border: 3px solid #555;
  
}

.RSVP input[type=radio] {
  margin: 10px;
  font-family: 'Garamond', serif;
  font-size: calc(10px + 2vmin);
  align-self: last baseline;
  margin-bottom: 20px;
}

.RSVP label {
  /* font-size: calc(10px + 2vmin); */
  color: #666;
  /* align to left */
  text-align: left;
  align-items: left;
  font-size: calc(12px + 2vmin);

}
/* B3C8CF BED7DC F1EEDC E5DDC5*/
.RSVPSearch {
  background-color: rgb(35, 31, 32);
  color: #e8e8dd;
  border: none;
  font-family: 'Garamond', serif;
  padding: 10px 20px;
  font-size: calc(10px + 2vmin);
  cursor: pointer;
  border-radius: 10x;
  width: 50%;
}

.ButtonRsvp {
  background-color: rgb(35, 31, 32);
  color: #e8e8dd;
  border: none;
  font-family: 'Garamond', serif;
  padding: 10px 20px;
  font-size: calc(10px + 2vmin);
  cursor: pointer;
  border-radius: 10x;
  width: clamp(100px, 45%, 400px);
  margin: 1px;

}

.ButtonRsvp:disabled {
  background-color: #918f8f;
  color: #e4e3e3;
}

.RSVPSearch:hover {
  background-color: #4f4c4d;
  transition: 0.2s;
}

/* when disabled */
.RSVPSearch:disabled {
  background-color: #918f8f;
  color: #e4e3e3;
}


.RSVPResult {
  /* background-color: #B3C8CF; */
  /* color: #000000; */
  border: 2px solid #000000;
  font-size: calc(10px + 2vmin);
  font-family: Garamond, serif;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 5px;
  width: 50%;
  max-width: 90;
  /* make it transparent */
  background-color: rgba(0, 0, 0, 0);
  color: #000000;
}

.RSVPResult:hover {
  background-color: #6666;
  transition: 0.2s;
}

